/* eslint-disable import/no-extraneous-dependencies */
import '@cedcommerce/ounce-ui/dist/index.css';
import 'nprogress/nprogress.css';
import '../styles/global.css';
import '../styles/customloader.css';
import cn from 'classnames';
import type { GetServerSideProps } from 'next';
import type { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import Script from 'next/script';
import { getServerSession } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { appWithTranslation } from 'next-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import nProgress from 'nprogress';
import { useEffect } from 'react';
import {
  QueryClient as QueryClientMain,
  QueryClientProvider,
} from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from '@/redux/store';
import { avertaFont } from '@/utils/avertaCyFontString';
import { options as authOptions } from '../pages/api/auth/[...nextauth]';

// Font files can be colocated inside of `pages`

const queryClient = new QueryClientMain({
  defaultOptions: {
    queries: {
      cacheTime: 0,
    },
  },
});

nProgress.configure({ showSpinner: false });

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      nProgress.start();
    });
    Router.events.on('routeChangeComplete', () => {
      nProgress.done();
    });
    Router.events.on('routeChangeError', () => nProgress.done());
  }, []);

  return (
    <main className={cn(avertaFont.className, 'app-container')}>
      {/* Google Analytics Script */}
      <style jsx global>
        {`
          *,
          body {
            font-family: ${avertaFont.style.fontFamily};
          }
        `}
      </style>
      <script
  type="module"
  src="https://cdn.jsdelivr.net/npm/shepherd.js@10.0.1/dist/shepherd.js"
></script>
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/shepherd.js@10.0.1/dist/css/shepherd.css"
/>
      <Script
        strategy='lazyOnload'
        src={`https://www.googletagmanager.com/gtag/js?id=UA-84385241-1`}
      />
      <Script
        strategy='lazyOnload'
        id='google_analytics_script'
      >{`window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', 'UA-84385241-1');`}</Script>

      {/* Ms Clarity script */}
      <Script
        src='https://www.clarity.ms/tag/gpwl5qphda'
        data-project-id='gpwl5qphda'
        strategy='lazyOnload'
      />
      <Script strategy='lazyOnload' id='clarity_script'>
        {`(function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = 'https://www.clarity.ms/tag/' + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, 'clarity', 'script', 'gpwl5qphda');`}
      </Script>

      {router.pathname.includes('hubspot') ||
      router.pathname.includes('share') ? (
        ''
      ) : (
        <Script src='//js.hs-scripts.com/5373140.js' />
      )}

      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <SessionProvider session={pageProps.session}>
            <Component {...pageProps} />
          </SessionProvider>
        </ReduxProvider>
      </QueryClientProvider>
    </main>
  );
};

export default appWithTranslation(MyApp);

export const getServerSideProps: GetServerSideProps = async context => {
  const session = await getServerSession(context.req, context.res, authOptions);

  return {
    props: {
      ...(await serverSideTranslations(context.locale as string, ['app'])),
      session,
    },
  };
};
